import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import HelmetWrapper from "routes/HelmetWrapper";
import {environment} from "config";

// sample page routing
const UploadPage = Loadable(lazy(() => import('../views/observation-workflow/uploadSteps/UploadStep1')));
const UploadPage2 = Loadable(lazy(() => import('../views/observation-workflow/uploadSteps/UploadStep2')));
const UploadPage3 = Loadable(lazy(() => import('../views/observation-workflow/uploadSteps/UploadStep3')));
const Profile = Loadable(lazy(() => import('views/user/profile/social-profile')));
const AccountProfile = Loadable(lazy(() => import('views/user/profile/account-profile')));
const ReportingModulePage = Loadable(lazy(() => import('../views/user/contributions/contributions-page')));
const EditLabelTemplatePage = Loadable(lazy(() => import('../views/user/contributions/utils/EditLabelTemplate')));
const DashboardPage = Loadable(lazy(() => import('views/dashboard/Dashboard')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/upload',
            element: (
                <HelmetWrapper canonicalUrl='/upload'>
                    <UploadPage />
                </HelmetWrapper>
            )
        },
        {
            path: '/UploadStep2',
            element: (
                <HelmetWrapper canonicalUrl='/UploadStep2'>
                    <UploadPage2 />
                </HelmetWrapper>
            )
        },
        {
            path: '/UploadStep3',
            element: (
                <HelmetWrapper canonicalUrl='/UploadStep3'>
                    <UploadPage3 />
                </HelmetWrapper>
            )
        },
        {
            path: '/profile/:tab',
            element: (
                <HelmetWrapper canonicalUrl='/profile'>
                    <Profile />
                </HelmetWrapper>
            )
        },
        {
            path: '/profile/settings',
            element: (
                <HelmetWrapper canonicalUrl='/profile/settings'>
                    <AccountProfile />
                </HelmetWrapper>
            )
        },
        {
            path: '/my-contributions',
            element: (
                <HelmetWrapper canonicalUrl='/my-contributions'>
                    <ReportingModulePage />
                </HelmetWrapper>
            )
        },
        {
            path: '/editLabel',
            element: (
                <HelmetWrapper canonicalUrl='/editLabel'>
                    <EditLabelTemplatePage />
                </HelmetWrapper>
            )
        },
        {
            path: '/dashboard',
            element: (
                <HelmetWrapper canonicalUrl='/dashboard'>
                    <DashboardPage />
                </HelmetWrapper>
            )
        }

    ]
};

export default MainRoutes;
